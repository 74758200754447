import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/Seo';
import ThreeColCta from '../../../../components/ThreeColCta';

const HorizonApp = () => (
	<Layout>
		<Seo
			title="Download Signia App | hear.com"
			description="Dowload the Signia hearing aids app by clicking here. Stay always connected with Signia hearing aids app."
		/>
		<div className="o-wrapper">
			<div className="o-row-2p5">
				<div className="c-newsletter-img">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png"
						alt="man sitting on couch and smiling"
						loading="lazy"
					/>
				</div>
			</div>
			<div className="o-row-2p5">
				<h1 className="c-content-hero__headline">Download Signia App</h1>
			</div>
			<ul className="c-usps">
				<li>Track your daily steps and more with your hearing aids </li>
				<li>Confidence and control over your hearing success </li>
				<li>Highly intuitive 24/7 support whenever you need it</li>
			</ul>
			<div className="c-app-box">
				<a
					className="c-app-box__panel c-app-box__android"
					href="https://play.google.com/store/apps/details?id=com.signia.rta"
				>
					<p className="c-app-box__android-title">Android</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/android-small.png"
						alt=""
					/>
				</a>
				<a className="c-app-box__panel c-app-box__ios" href="https://apps.apple.com/us/app/signia-app/id1316911473">
					<p className="c-app-box__ios-title">iOS</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/ios-small.png"
						alt=""
					/>
				</a>
			</div>
			<section className="c-app-section">
				<div className="o-row-2p5">
					<p className="c-app-section__title">Signia app</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						The Signia app offers a comprehensive suite of features to enhance your hearing aid experience, all
						customized to match your individual preferences. From audio streaming to remote control and support, it has
						everything you need conveniently accessible through your smartphone. Simplified and intuitive, the app
						allows discreet adjustment of volume and other settings while seamlessly streaming audio directly to your
						ears.
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Additionally, with the Signia app, you have instant access to support through the innovative Signia
						Assistant, ensuring assistance whenever and wherever it's needed. Plus, you can maintain communication with
						your Hearing Care Professional via TeleCare, receiving remote support even when an in-person visit isn't
						possible.
					</p>
				</div>
			</section>
			<div className="o-row-2p5">
				<p className="c-app-section__title">Resources</p>
			</div>
			<div className="o-row-2p5">
				<ThreeColCta
					ImgSrc1="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
					ContentTitle1="Hearing Test"
					ContentCopy1="Check how is your hearing in less than 5 minutes."
					Url1="/hearing-test/"
					ImgSrc2="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg"
					ContentTitle2="Hearing Loss"
					ContentCopy2="There are several types of hearing loss and many different cause."
					Url2="/hearing-loss/"
					ImgSrc3="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg"
					ContentTitle3="Hearing Aids"
					ContentCopy3="Connect you to the world around you with our wide range of devices."
					Url3="/hearing-aids/"
				/>
			</div>
		</div>
	</Layout>
);

export default HorizonApp;
